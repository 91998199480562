<template>

  <main class="contact">

    <div class="container">
      <div class="row">
        <div class="col s12">

          <router-link to="/" title="Radio Kraków Kultura" class="breadcrumb">
            Radio Kraków Kultura
          </router-link>

          <router-link to="/audycje" title="Audycje" class="breadcrumb">
            Audycje
          </router-link>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="row">
        <div class="col s12">
          <h1 class="big-title">Audycje</h1>
        </div>
      </div>


      <div class="row" :class="{loading: stateCompare('loading')}">

        <div class="col s12 m6 l4 xl4" v-for="family in familys">
          <BroadcastBlock :family="family"/>
        </div>
      </div>

    </div>
  </main>
  <SectionInfo code="broadcastfamilylist"/>
</template>

<script>
import BroadcastBlock from "@/views/Audio/Part/BroadcastBlock";
import axios from "@/axios2";
import CoreArray from "@/helpers/array.js";
import ComponentListStateMachine from "../../Extends/ComponentListStateMachine";
import SectionInfo from "../Home/SectionInfo";

export default {
  name: "BroadcastList",
  components: {SectionInfo, BroadcastBlock},
  extends: ComponentListStateMachine,
  data() {
    return {
      familys: null,
    }
  },
  created() {

    let headers = {
      'objs_count': 999999
    }

    this.stateUpdate('sendInfo');

    axios
        .get('articlefamily/get', {
          params: {
            sort: 'title',
            count: 999999,
            status: 'live',
            scope: 'title,url,thumb,users',
            users_scope: 'name,account_type,firstname,lastname,avatar_url,url_full'
          }, headers: headers

        })
        .then(response => {

          let familys = response.data.data;

          let from = "https://off.radiokrakow.pl/api/"
          let to = "https://off.radiokrakow.pl/"
          this.familys = familys;
          // this.familys = CoreArray.changeDomain(familys,'thumb',from,to);

          this.stateUpdate('sendSuccess');

        })
        .catch(error => {
          this.refresh();

          this.stateUpdate('sendWrong');

        });

  }

}
</script>

<style scoped>

</style>